import React, { useState } from "react"
import axios from "axios"
import ReactIntlTelInput from "react-intl-tel-input-v2"

import NameInputIcon from "../../images/name-input-icon.svg"
import EmailInputIcon from "../../images/email-input-icon.svg"

import "intl-tel-input/build/css/intlTelInput.css"
import "./index.scss"

const JoinListForm = () => {
  const [data, setData] = useState({
    fullname: {
      error: null,
      value: "",
      rules: [v => !!v || "Name is required!"],
    },
    email: {
      error: null,
      value: "",
      rules: [
        v => !!v || "Email is required!",
        v =>
          !!v.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          ) || "Please enter a valid email!",
      ],
    },
    mobile: {
      error: null,
      value: "",
      rules: [
        v => !!v || "Mobile Number is required!",
        v =>
          !!v.match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
          ) || "Please enter a valid mobile number!",
      ],
    },
  })
  const [telInputInit, setTelInputInit] = React.useState({ iso2: 'us', dialCode: '1', phone: '' })
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    const temp = { ...data }
    temp[name] = {
      ...temp[name],
      value,
      error: null,
    }

    setData({ ...temp })
  }

  const submitHandler = async e => {
    e.preventDefault()

    if (loading) {
      return false
    }

    try {
      setLoading(true)
      const temp = { ...data, mobile: { ...data.mobile, value: telInputInit.phone ? `+${telInputInit.dialCode}${telInputInit.phone}` : "" } }
      const formData = {}
      let isValid = true

      for (const x in temp) {
        temp[x].error = null

        if (temp[x].rules) {
          for (const r of temp[x].rules) {
            const error = r(temp[x].value)
            temp[x].error = error

            if (error !== true) {
              isValid = false
              break
            }
          }
        }

        formData[x] = temp[x].value
      }

      if (!isValid) {
        setData({ ...temp })
        return false
      }

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/early-access`,
        { ...formData }
      )

      setSuccess("Thank you for signing up, We'll get back to you soon!")
    } catch (err) {
      console.log(err)
      alert("Something went wrong, Please try again!")
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={submitHandler} className="w-full">
      <div className="w-full pb-8">
        <div className="relative block mx-auto fs-16 fs-lg-20 fs-xl-20 w-full pb-4 mb-8 input-wrapper">
          <img
            className="inline absolute"
            src={NameInputIcon}
            alt="Name"
            style={{ top: 10, left: 8 }}
          />
          <input
            type="text"
            name="fullname"
            placeholder="Your name"
            onChange={onChange}
            className="block mx-auto fs-16 fs-lg-20 fs-xl-20 w-full pl-10"
            style={{ paddingLeft: 40 }}
          />
          {data.fullname.error && (
            <span className="input-error">{data.fullname.error}</span>
          )}
        </div>
        <div className="relative block mx-auto fs-16 fs-lg-20 fs-xl-20 w-full pb-4 mb-8 input-wrapper">
          <img
            className="inline absolute"
            src={EmailInputIcon}
            alt="Email"
            style={{ top: 10, left: 8 }}
          />
          <input
            type="email"
            name="email"
            placeholder="Your email"
            onChange={onChange}
            className="block mx-auto fs-16 fs-lg-20 fs-xl-20 w-full pl-10"
            style={{ paddingLeft: 40 }}
          />
          {data.email.error && (
            <span className="input-error">{data.email.error}</span>
          )}
        </div>
        <div className="relative block mx-auto fs-16 fs-lg-20 fs-xl-20 w-full pb-4 mb-8 input-wrapper">
          <ReactIntlTelInput
            inputProps={{
              className: "block mx-auto fs-16 fs-lg-20 fs-xl-20 w-full pl-10",
              placeholder: "Your mobile number",
            }}
            className="intl-tel-input"
            IntlTelOpts={{
              initialCountry: "us",
              preferredCountries: ["us", "gb"],
              separateDialCode: true,
            }}
            value={telInputInit}
            onChange={value => setTelInputInit(value)}
          />
          {data.mobile.error && (
            <span className="input-error">{data.mobile.error}</span>
          )}
        </div>
      </div>
      {!success && (
        <button
          className="font-bold px-12 py-3 btn btn-white block mx-auto"
          type="submit"
          disabled={loading}
        >
          <span className="text-gradient font-bold fs-16 fs-lg-20 fs-xl-20">
            Join Waitlist
          </span>
        </button>
      )}
      {success && (
        <span className="text-primary fs-16 fs-lg-20 fs-xl-24 text-center flex justify-center">
          {success}
        </span>
      )}
    </form>
  )
}

export default JoinListForm
